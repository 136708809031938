import { useState, useEffect } from "react"
import { useParams } from 'react-router'
import { useLocation } from "react-router-dom";
import styles from '../assets/css/article.module.css'
import api from '../components/api'
import Nav from "../components/Template/Nav";
import Table from "../components/EBook/TermPaper";
import PaperDetail from "../components/EBook/TermPaperDetail";

export default function TermPaper(props) {
    const [nav_data, setNavData] = useState([]);
    const [paper, setPaper] = useState({});
    const [papers, setPapers] = useState([]);
    const params = useParams();
    let paper_id = (params.id)?params.id:0;

    const fetchInit = async () =>{
        try {
            const menu_response = await api.get('/menu/13');
            setNavData(menu_response.data);
            if(paper_id){
                const response = await api.get('/term_paper/'+paper_id+'?published=1');
                const results = response.data.results;
                setPaper(results);
            }else{
                const response = await api.get('/term_paper?published=1');
                const results = response.data.results;
                setPapers(results);
            }
        } catch (err) {
            if(err.response){
                //Not in 202 response range
                console.log(err.response.data);
                console.log(err.response.headers);
                console.log(err.response.status);
            } else {
                console.log(`Error: ${err.message}`);
            }
        }
    }

    useEffect(()=>{
        fetchInit();
        window.addEventListener("scroll", reveal);
        reveal();
    },[]);

    function reveal() {
        var reveals = document.querySelectorAll(".reveal");
        for (var i = 0; i < reveals.length; i++) {
          var windowHeight = window.innerHeight;
          var elementTop = reveals[i].getBoundingClientRect().top;
          var elementVisible = 150;
          if (elementTop < windowHeight - elementVisible) {
            reveals[i].classList.add("active");
          } else {
            reveals[i].classList.remove("active");
          }
        }
    }

    return (
        <div className={styles["article-container"]}>
            <div className={"content reveal"}>
                <article className={styles["article"]}>
                    <Nav styles={styles} nav_data={nav_data} />
                    {(parseInt(paper_id)>0)?(
                        <PaperDetail styles={styles} data={paper} />
                    ):(
                        <Table styles={styles} papers={papers} />
                    )}
                </article>
            </div>
        </div>
    )
}