import React, { useState, useEffect } from 'react';
import {lang} from '../lang';

const FilterComponent = (props) => {
	const [role, setRole] = useState('User');
	const today = new Date().toISOString().split('T')[0];

	useEffect(() => {
		const user_role = localStorage.getItem('role');
		if(user_role){
		setRole(user_role);
		}
	},[]);

    return (
    <>
		<fieldset className="datatable-filter">
			<div className="input-group">
			<input 
				type="text" 
				id="search" 
				placeholder={lang('ระบุคำค้น','Enter Keyword')}
				value={props.filterText} 
				onChange={props.onFilter} 
				aria-label="Search Input" 
				aria-describedby="datatable-search" />
			</div>
		</fieldset>
		<small className="w-100 pb-2">{props.info}</small>
	</>
    )
};

export default FilterComponent;