import React, { useState, useEffect } from 'react'
import { lang, gotoURL } from '../lang'
import DataTable from 'react-data-table-component';
import FilterComponent from './FilterComponent';
import FieldValue from "../Template/FieldValue";

export default function TermPaper(props) {
  const {styles,papers} = props;
  const [filterText, setFilterText] = useState('');
  const [listsdata, setListsdata] = useState(papers);

  const cols = [
    {
        name: lang('ลำดับที่','#'),
        selector: (row, index) =>  Number(index+1),
        center: true,
        width: '90px',
    },
    {
        name: lang('รหัส','Codes'),
        selector: row => <FieldValue value={row.Codes} keyword={filterText}/>,
        center: true,
        sortable: false,
        width: '90px',
    },
    {
        name: lang('ขื่อเรื่อง','Title'),
        selector: row => <FieldValue value={row.TitleTh} keyword={filterText}/>,
        center: true,
        wrap: true
    },
    {
        name: lang('ปี','Years'),
        selector: row => <FieldValue value={row.Years} keyword={filterText}/>,
        sortable: false,
        center: true,
        width: '90px',
    },
    {
        name: lang('รายละเอียด','Detail'),
        selector: row => 
        <div className="text-center">
            <button className='link edit' onClick={()=>openDetail(row.Id)}>
                <i className="fa-solid fa-file-lines"></i>
            </button>
        </div>,
        center: true,
        width: '90px',
    },
];

useEffect(()=>{
    document.title = lang("ภาคนิพนธ์","Term Paper")+' - ห้องสมุดคณะวิศวกรรมศาสตร์ มหาวิทยาลัยเชียงใหม่';
    setListsdata(papers);
},[papers]);

const openDetail = (id) => {  
    gotoURL('/termpaper/'+id);
}

const onFilter = (txt) => {  
    let filteredItems = papers.filter(
        item => {
            return item.Id.includes(txt.toLowerCase()) || 
                item.TitleTh.includes(txt.toLowerCase())|| 
                item.Years.includes(txt.toLowerCase())|| 
                item.Codes.includes(txt.toLowerCase())|| 
                item.TitleEn.includes(txt.toLowerCase());
        }
    );

    setListsdata(filteredItems);
    setFilterText(txt);
}

/* filter and clearfilter */
const handleClear = () => {
    const txt = '';
    let filteredItems = papers.filter(
        item => {
            return item.Id.includes(txt.toLowerCase()) || 
                item.TitleTh.includes(txt.toLowerCase())|| 
                item.Years.includes(txt.toLowerCase())|| 
                item.Codes.includes(txt.toLowerCase())|| 
                item.TitleEn.includes(txt.toLowerCase());
        }
    );
    setListsdata(filteredItems);
    setFilterText('');
};

  return (
    <div className={styles["article-content"]}>
        <h3>{lang("ภาคนิพนธ์","Term Paper")}</h3>
        <div className={styles["article-detail"]}>
            <DataTable 
                columns={cols} 
                data={listsdata} 
                pagination 
                paginationComponentOptions={ {rowsPerPageText: lang('แสดงข้อมูลแถว:','Total Row:'), rangeSeparatorText:  lang('จาก','to'), noRowsPerPage: false, selectAllRowsItem: true, selectAllRowsItemText: lang('แสดงทั้งหมด','All Rows') }}
                subHeader
                striped
                subHeaderComponent={<FilterComponent onFilter={e => onFilter(e.target.value)} onClear={handleClear} filterText={filterText} export={false}/>}
                paginationPerPage={10} />
            </div>
    </div>
  )
}
