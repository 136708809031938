import React, { useState, useEffect } from 'react'
import { thDateFormat, lang } from '../lang'
import styles from '../../assets/css/ebook.module.css';

export default function TermPaperDetail(props) {
  const {data} = props;

  useEffect(()=>{
    document.title = data.TitleTh+' - ห้องสมุดคณะวิศวกรรมศาสตร์ มหาวิทยาลัยเชียงใหม่';
  },[data])

  return (
    <div className={styles["book-detail"]}>
        <div className={styles["paper-info"]}>
            <h3 className={styles["title"]}>{data.TitleTh}</h3>
            {(data.Codes!=='')?<p className={styles["review"]}><span>{lang('รหัส','Code')}:</span> {data.Codes}</p>:''}
            {(data.Author!=='')?<p className={styles["classification"]}><span>{lang('ผู้แต่ง','Author')}:</span> {data.Author}</p>:''}
            {(data.Years!=='')?<p className={styles["detail-author"]}><span>{lang('ปีที่พิมพ์','Year')}:</span> {data.Years}</p>:''}
            {(data.Calls!=='')?<p className={styles["detail-isbn"]}><span>{lang('เลขเรียกหนังสือ','Book No.')}:</span> {data.Calls}</p>:''}
            {(data.Imprints!=='')?<p className={styles["published"]}><span>Imprints:</span> {data.Imprints}</p>:''}
            {(data.Note!=='')?<p className={styles["link"]}><span>{lang('รายละเอียดเพิ่มเติม','Note')}:</span> {data.Note}</p>:''}
            {(data.Links!=='')?<p className={styles["link"]}><span>Link:</span> <a href={data.Links} target="_blank" rel="noopener noreferrer">{lang('ดูรายละเอียด','more detail')}</a></p>:''}
            {(data.FileUpload!=='')?<p className={styles["link"]}><span>FileUpload:</span> <a href={data.FileUpload} target="_blank" rel="noopener noreferrer">{lang('ดูรายละเอียด','more detail')}</a></p>:''}
            {(data.DateAdd!=='')?<p className={styles["link"]}><span>{lang('วันที่เพิ่มข้อมูล','Date Add')}:</span> {thDateFormat(data.DateAdd)}</p>:''}
            <div className={styles["back"]}>
                <a href={process.env.PUBLIC_URL + "/termpaper"}>{lang('กลับหน้าหลัก','Back')}</a>
            </div>
        </div>
    </div>
    )
}